import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router, { setupRouter } from './router'
import { setupElementPlus } from '@/setup/element-plus/index'
import { setupStore } from '@/store/index'
import 'normalize.css'
import '@/assets/styles/index.scss'
import 'amfe-flexible/index.js'

import { Viewer } from 'v-viewer'
// Viewer.setDefaults({
//   Options: {
//     'inline': true,
//     'button': true,
//     'navbar': true,
//     'title': true,
//     'toolbar': true,
//     'tooltip': true,
//     'movable': true,
//     'zoomable': true,
//     'rotatable': true,
//     'scalable': true,
//     'transition': true,
//     'fullscreen': true,
//     'keyboard': true,
//     'url': 'data-source'
//   }
// })



const app = createApp(App)

// setup element-plus
setupElementPlus(app)

// setup vuex store
setupStore(app)

// setup router
setupRouter(app)

// mount app when router is ready
router.isReady().then(() => {
  app.mount('#app')
})
