<template>
  <div class="header">
    <!-- brand -->
    <!-- <router-link
      to="/home"
      custom
      v-slot="{navigate}"
    >
      <div class="header-brand">
        <img
          src="@/assets/首页/logo.png"
          width="136"
          height="72"
          alt="新生涯"
          title="新生涯"
          @click="navigate"
        >
      </div>
    </router-link> -->

    <!-- navigation -->
    <HeaderNavigation class="header-nav" />
   
    <!-- search -->
    <!-- <HeaderSearch /> -->

    <!-- login area -->
    <HeaderLoginArea />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderNavigation from "./modules/navigation.vue";
import HeaderSearch from "./modules/search.vue";
import HeaderLoginArea from "./modules/loginArea.vue";
export default defineComponent({
  name: "Header",
  components: {
    HeaderNavigation,
    // HeaderSearch,
    HeaderLoginArea,
  },
});
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/responsive.scss";
.header {
  width: 1200px;
  margin: 0 auto;
  &-brand {
    float: left;
    margin: 0 20px;
    cursor: pointer;
    @include respond-to {
      margin: 0 10px;
    }
    img {
      width: 150px;
      height: 50px;
      margin-top: 14px;
    }
  }
  &-nav {
    cursor: pointer;
  }
  .top{
    .topImg{
      width: 1920px;
      height: 497px;
    }
  }
}
</style>
