<template>
  <div class="footer">
   
    <div class="footer-copyright">
       <p class="copy">
        北京新生涯教育科技有限公司 版权所有 2018-2028             
        <a target="_blank" href="https://beian.miit.gov.cn"
          >京ICP备19038857号-1        </a
        >
        Powered by 北京新生涯教育科技有限公司
      </p>
      <img class="footerImg" :src="footerImg" alt="">
      <div class="info">
        <p><router-link class="first" to="/home">返回首页</router-link> | <a target="_blank" href="https://cloud.xsygh.com/#/login?redirect=%2Fdashboard">生涯云平台</a> |  <router-link to="/aboutUs">关于我们</router-link>   </p>
        <p>股票代码    300010</p>
        <p>联系电话    010-83058502</p>
        <p>联系地址    北京市海淀区中关村软件园立思辰大厦</p>
        
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onBeforeMount } from "vue";
import { getFooterLink } from "@/api/common";
import { FooterLinkConfig } from "@/types";
import { ERR_OK } from "@/api/config";
export default defineComponent({
  name: "Footer",
  data() {
    return {
      list: [
        { title: "师资培训", url: "/", target: "_blank" },
        { title: "其他学校", url: "/aboutUs", target: "_blank" },
        { title: "智慧课堂", url: "/", target: "_blank" },
        { title: "生涯咨询", url: "/", target: "_blank" },
        { title: "研学营", url: "/", target: "_blank" },
        { title: "云平台", url: "/", target: "_blank" },
        { title: "公司新闻", url: "/", target: "_blank" },
        { title: "行业动态", url: "/", target: "_blank" },
        { title: "公司介绍", url: "/", target: "_blank" },
        { title: "师资介绍", url: "/", target: "_blank" },
        { title: "加盟合作", url: "/", target: "_blank" },
        { title: "联系我们", url: "/", target: "_blank" },
      ],
         footerImg:require("@/assets/1new/首页/footer.jpg"),
    };
  },
  setup() {
    const linkList = ref<FooterLinkConfig[]>([]);
    onBeforeMount(async () => {
      const { code, data } = await getFooterLink();
      console.log(code, data);
      if (code === ERR_OK && data) {
        linkList.value = data;
      }
      // console.log(code,linkList);
    });
    return { linkList };
  },
});
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/mixin.scss";
.footer {
  position: relative;
  // &-center {
  //   padding: 6px 0;
  //   position: relative;
  //   @include mooc-center();
  //   .left {
  //     width: 230px;
  //     margin-right: 60px;
  //     display: inline-block;
  //     text-align: center;
  //     img {
  //       margin-top: 0;
  //       width: 150px;
  //       height: 50px;
  //     }
  //     .new {
  //       font-size: 24px;
  //       color: #999999;
  //       margin-top: 30px;
  //     }
  //   }
  //   .center {
  //     width: 230px;
  //     display: inline-block;
  //   }
  //   .right {
  //     width: 230px;
  //     display: inline-block;
  //   }
  // }
  // &-link {
  //   text-align: center;
  //   position: absolute;
  //   top: 0;
  //   .sort {
  //     color: #fff;
  //     font-size: 16px;
  //     text-align: center;
  //   }
  //   &-item {
  //     // display: inline-block;
  //     // vertical-align: middle;
  //     margin-right: 24px;
  //     font-size: $font-normal;
  //     line-height: 28px;
  //     color: rgba(#fff, 0.6);
  //     cursor: pointer;
  //     &:hover a {
  //       color: #fff;
  //     }
  //     a {
  //       color: inherit;
  //     }
  //   }
  // }
  &-copyright {
    position: relative;
    width: 1200px;
    text-align: center;
    font-size: $font-small;
    // color: rgba(#fff, 0.4);
    line-height: 24px;
  .copy{
    width: 1920px;
      position: absolute;
      top: 200px;
     text-align: center;
      z-index: 99;
      color: #fff;
    }
    a{
      margin-left: 20px;
      margin-right: 20px;
    }
    & > span {
      margin-right: 10px;
    }
  
  }
  .footerImg{
    width: 1920px;
    height: 246px;
    position: absolute;
    top: -0px;
    left: 0;
  }
  .info{
    width: 320px;
    height: 120px;
    // background-color: #fff;
    position: absolute;
    left: 1080px;
    top: 70px;
    font-size: 14px;
    color: #f1f1f1;
  }
  p{
    margin-bottom: 18px;
    text-align: left;
    .first{
      margin-left: -1px;
    }
  }
}
</style>
