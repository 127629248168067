<template>
  <div class="main">
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LayoutMain'
})
</script>
