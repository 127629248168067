<template>
  <div class="layout">
    <div class="top">
      <img class="topImg" src="../assets/1new/首页/新生涯网站首页_01.jpg" alt="">
    </div>
    <LayoutHeader
      v-show="!meta.hideHeader"
      class="layout-header"
    >
      <Header class="headCenter" />
    </LayoutHeader>
    <!--  -->
    <LayoutMain class="layout-main" />
    <!--  -->
    <LayoutFooter v-show="!meta.hideFooter" class="layout-footer">
      <Footer  class="footerCenter"/>
    </LayoutFooter>
    <Sidebar v-show="!meta.hideSidebar" />
  </div>
   
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import LayoutHeader from './header/index.vue'
import LayoutMain from './main/index.vue'
import LayoutFooter from './footer/index.vue'
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import Sidebar from '@/components/sidebar/index.vue'
export default defineComponent({
  name: 'Layout',
  components: {
    LayoutHeader,
    LayoutMain,
    LayoutFooter,
    Header,
    Footer,
    Sidebar
  },
  setup () {
    const { meta } = toRefs(useRoute())
    return { meta }
  }
})
</script>
<style lang="scss" scoped>
  @import '~@/assets/styles/variables.scss';
  $header-height: 40px;
  .layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 1900px;
    margin-bottom: -20px;
    overflow: hidden;
    background-color: #f5f5f5;
    // background-color: skyblue;
    .top{
      width: 1920px;
      height: 100px;
      position: fixed;
      top: 0;
      z-index: 99;
      .topImg{
        width: 1920px;
        height: 103px;
        margin-top: -0px;
      }
    }
    &-header {
      z-index: 100;
      position: fixed;
      left: 0;
      top: 100px;
      right: 0;
      height: $header-height;
      background-color: #00a5a8;
      box-shadow: $box-shadow-normal;
    }
    &-main {
      flex: auto;
      width: 1200px;
      margin: 0 auto;
      margin-top: 140px;
    }
    &-footer {
      width: 1920px;
      height: 246px;
      // background-color:#2f2929;
      // overflow: hidden;
      // padding-bottom: 20px;
    }
  }
  

</style>
