
import { defineComponent, ref, onBeforeMount } from "vue";
import { getFooterLink } from "@/api/common";
import { FooterLinkConfig } from "@/types";
import { ERR_OK } from "@/api/config";
export default defineComponent({
  name: "Footer",
  data() {
    return {
      list: [
        { title: "师资培训", url: "/", target: "_blank" },
        { title: "其他学校", url: "/aboutUs", target: "_blank" },
        { title: "智慧课堂", url: "/", target: "_blank" },
        { title: "生涯咨询", url: "/", target: "_blank" },
        { title: "研学营", url: "/", target: "_blank" },
        { title: "云平台", url: "/", target: "_blank" },
        { title: "公司新闻", url: "/", target: "_blank" },
        { title: "行业动态", url: "/", target: "_blank" },
        { title: "公司介绍", url: "/", target: "_blank" },
        { title: "师资介绍", url: "/", target: "_blank" },
        { title: "加盟合作", url: "/", target: "_blank" },
        { title: "联系我们", url: "/", target: "_blank" },
      ],
         footerImg:require("@/assets/1new/首页/footer.jpg"),
    };
  },
  setup() {
    const linkList = ref<FooterLinkConfig[]>([]);
    onBeforeMount(async () => {
      const { code, data } = await getFooterLink();
      console.log(code, data);
      if (code === ERR_OK && data) {
        linkList.value = data;
      }
      // console.log(code,linkList);
    });
    return { linkList };
  },
});
