
import { defineComponent } from "vue";
import HeaderNavigation from "./modules/navigation.vue";
import HeaderSearch from "./modules/search.vue";
import HeaderLoginArea from "./modules/loginArea.vue";
export default defineComponent({
  name: "Header",
  components: {
    HeaderNavigation,
    // HeaderSearch,
    HeaderLoginArea,
  },
});
