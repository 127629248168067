
import { defineComponent, ref, onBeforeMount } from 'vue'
import { useScroll } from '@/hooks/event/useScroll'
import { getSidebar } from '@/api/common'
import { ERR_OK } from '@/api/config'
import { SidebarItemConfig } from '@/types'
export default defineComponent({
  name: 'Sidebar',
  setup () {
    const showBackBtn = ref(false)
    const sidebarList = ref<SidebarItemConfig[]>([])
    const { scrollTo } = useScroll(window, (scrollTop: number) => {
      showBackBtn.value = scrollTop > 200
    })
    const handleScrollToTop = () => scrollTo()
    onBeforeMount(async () => {
      const { code, data } = await getSidebar()
      if (code === ERR_OK && data) {
        sidebarList.value = data
      }
    })
    return {
      sidebarList,
      showBackBtn,
      handleScrollToTop
    }
  }
})
